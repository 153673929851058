<template>
  <b-tabs v-model="tabIndex" class="d-md-block d-lg-block">
    <b-tab
      :title="tab.name"
      :active="tabIndex == index"
      v-for="(tab, index) in tabs"
      :key="index"
      @click="onTabClick(tab, tab.tabId)"
    >
      <div class="mp-result-found d-none d-md-block d-lg-block">
        <div v-if="courses && courses.count > 0">
          {{
            $t("search_course.results_found", {
              count: courses.count
            })
          }}
        </div>
      </div>
      <div v-if="isLoading" class="cd-list__loader">
        <div class="cd-list__loader--div" v-for="c in 4" :key="c">
          <shimmer variant="landing-course-card" />
        </div>
      </div>
      <div class="d-block" v-else>
        <div
          class="ld-sec__grid-layout"
          v-if="courses.results && courses.results.length > 0"
        >
          <div
            class="my-learning__card-div"
            v-for="(course, index) in courses.results"
            :key="index"
          >
            <CourseCard
              :courseImage="
                course.image_url
                  ? course.image_url
                  : require('@/assets/images/illustration/course-illustration.svg')
              "
              :org="course.org"
              :courseTitle="course.title"
              :language="language"
              :pacing="course.pacing"
              :courseLanguage="course.language"
              :index="`${tab.tabId}-course-card-${index}`"
              :currentServerTime="currentServerTime"
              @click="onCard(course, tab.tabId)"
              @orgClick="onOrgClick(course)"
              :status="getCardStatus(tab.tabId)"
              @unenrollModalData="unenrollModalData"
              :course="course"
            >
            </CourseCard>
          </div>
        </div>

        <div v-else>
          <!-- error component -->
          <my-programme-empty-state
            :tab-index="tabIndex"
          ></my-programme-empty-state>
        </div>
        <div v-if="isLazyLoading">
          <LxpLoader />
        </div>
        <unenroll-modal
          @unenrollSuccessModalClose="unenrollSuccessModalClose"
          :courseId="selectedCourseId"
        ></unenroll-modal>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import axios from "axios";
import { LxpLoader } from "didactica";
import fetchTabContentMixin from "@/components/MyLearning/mixins/fetchTabContent";
import MyProgrammeEmptyState from "@/components/MyLearning/MyProgrammeEmptyState.vue";
import UnenrollModal from "@/components/CourseDetail/Modals/UnenrollModal.vue";
export default {
  mixins: [fetchTabContentMixin],
  data() {
    return {
      selectedCourseId: "",
      seeAllSidebar: false,
      isLazyLoading: false
    };
  },
  components: {
    MyProgrammeEmptyState,
    UnenrollModal,
    LxpLoader
  },
  mounted() {
    document
      .getElementById("userFluidLayoutMainViewWrapper")
      .addEventListener("scroll", this.amountScrolled);
  },
  methods: {
    onCard(c, t) {
      if (t === "ip") {
        this.$router.push({
          name: "Self Paced",
          params: { id: c.id },
          query: { from: "learning-programmes" }
        });
      } else {
        this.$router.push({
          name: "Courses Detail",
          params: { id: c.id },
          query: { from: "learning-programmes" }
        });
      }
    },
    onTabClick(tab) {
      this.tabs.forEach(t => (t.active = false));
      tab.active = true;
      this.isLoading = true;
      this.fetchApiData(tab.tabId);
    },
    getCardStatus(tabId) {
      switch (tabId) {
        case "ip":
          return "CONTINUE_LEARNING";
        case "ss":
          return "ENROLLED";
        case "co":
          return "COMPLETED";
        case "ar":
          return "ARCHIVED";
        default:
          return null;
      }
    },
    unenrollModalData(data) {
      this.$bvModal.show(data.modalId);
      this.selectedCourseId = data.courseId;
    },
    unenrollSuccessModalClose() {
      this.isLoading = true;
      this.tabIndex === 0
        ? this.getInProgressCourses()
        : this.getStartingSoonCourses();
    },
    amountScrolled() {
      let courseCardsWrapper = document.getElementById(
          "userFluidLayoutMainViewWrapper"
        ),
        documentHeight =
          courseCardsWrapper.scrollHeight - courseCardsWrapper.clientHeight,
        scrollTopPosition = courseCardsWrapper.scrollTop,
        scrolledPercentage = Math.floor(
          (scrollTopPosition / documentHeight) * 100
        );
      if (
        scrolledPercentage >= 75 &&
        !this.isLazyLoading &&
        !this.isLoading &&
        this.courses.next !== null
      ) {
        this.isLazyLoading = true;
        axios
          .get(this.courses.next, {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`
            }
          })
          .then(res => {
            this.isLazyLoading = false;
            let data = res.data;
            data.results = this.courses.results.concat(data.results);
            this.$store.commit(this.mutationString, data);
          })
          .catch(() => {
            this.isLazyLoading = false;
          });
      }
    },
    fetchApiData(tabId) {
      switch (tabId) {
        case "ip":
          this.getInProgressCourses();
          break;
        case "ss":
          this.getStartingSoonCourses();
          break;
        case "co":
          this.getCompletedCourses();
          break;
        case "ar":
          this.getArchivedCourses();
          break;
        default:
          return null;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.responsive-course-card-title {
  @include button-label;
  text-align: left;
  letter-spacing: 0.15px;
  margin: 24px 0 15px;
  color: $brand-neutral-700;

  img {
    margin-right: 12px;
  }
}

.responsive-course-card {
  display: flex;
  overflow: auto;
  gap: 16px;
  &::-webkit-scrollbar {
    display: none;
  }
}

#see-all-sidebar {
  .lx-course-card-mobile-view {
    width: 100%;
  }
}

// rtl layout
[dir="rtl"] {
  .mp-result-found {
    text-align: right;
  }
  .responsive-course-card-title {
    img {
      margin-right: 0px;
      margin-left: 12px;
    }
  }
}
</style>
